import { useState, useEffect, useRef, useMemo } from 'react';
import { HistoryType } from 'src/@types/historyTables';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { notify } from 'src/utils/notifications';
import DataApiClient, {
	ShortTermDataApiParams,
} from '../../utils/DataApiClient';
import useHistoryTableFilterStore from '../../stores/historyTableFilterStore/useHistoryTableFilterStore';
import useCurrentAuthority from '../useCurrentAuthority';
import { useTrackNextDataPageToken } from './useTrackNextPageToken';
import { SHORT_TERM_API_PAGE_SIZE } from '../../constants/historyTables';

type PageNumber = number;

const DEFAULT_PAGINATED_RECORDS_MAP = new Map<PageNumber, []>();
const DEFAULT_PAGE_RECORDS: any[] = [];

/**
 * Generic hook for managing short term data API requests.
 * Short term data refers to data collected in the last 31 days.
 * Handles pagination and page token tracking for any history type.
 * Cache pages that are already fetched (this is a limitation/feature of the token-based pagination API server = a non-first page won't be updated unless we fetch from the first page again).
 *
 * TODO :: Optional Improvement :: Consider keeping short term history for all pages cached. When we return to a page already in the cahce, we use the cached data and lazy-load in the background and replace the state with the new data. E.g. :: If we were previous on page 1 of the trades page, and navigate to another page, when we return to the trades page the previous data should be instantly available.
 */
export const useShortTermDataApi = <T,>(
	historyType: HistoryType,
	deserializer: (r: Record<string, unknown>) => T,
	enabled: boolean,
	params?: ShortTermDataApiParams
) => {
	const [records, setRecords] = useState<Map<PageNumber, T[]>>(
		DEFAULT_PAGINATED_RECORDS_MAP
	);
	const [loading, setLoading] = useState(false);
	const nextDataPageTokenTracker = useTrackNextDataPageToken();
	const currentAccountPubKey = useDriftAccountStore((s) =>
		s.getCurrentUserAccount()?.pubKey?.toString()
	);
	const currentAuthority = useCurrentAuthority();
	const prevHistoryType = useRef<HistoryType>(historyType);
	const stableParams = JSON.stringify(params);
	const stableParamsRef = useRef(stableParams);

	// data page and ui page are the same for short term data
	const [currentApiPage, setCurrentApiPage] = useState(0);
	const setHistoryTableFilterStoreState = useHistoryTableFilterStore(
		(s) => s.set
	);
	const setCurrentUiPage = (newPage: number) => {
		setHistoryTableFilterStoreState((s) => {
			// @ts-ignore TODO: it says this doesn't exist on the type!!!
			s[historyType as keyof typeof s].currentUiPage = newPage;
		});
	};

	// Reset the history state any time the user account changes or this component is unmounted
	useEffect(() => {
		return () => {
			setRecords(DEFAULT_PAGINATED_RECORDS_MAP);
			nextDataPageTokenTracker.clearPageTokens();
			setCurrentApiPage(0);
			setCurrentUiPage(0);
		};
	}, [currentAccountPubKey]);

	/**
	 * This hook is responsible for fetching new data when necessary
	 */
	useEffect(() => {
		if (!enabled) return;

		if (!currentAccountPubKey || !currentAuthority) {
			setRecords(DEFAULT_PAGINATED_RECORDS_MAP);
			return;
		}

		if (
			records.has(currentApiPage) &&
			prevHistoryType.current === historyType &&
			stableParamsRef.current === stableParams
		) {
			return;
		}

		prevHistoryType.current = historyType;
		stableParamsRef.current = stableParams;
		const pageToken = nextDataPageTokenTracker.pageTokens.get(currentApiPage);

		if (currentApiPage > 0 && !pageToken) {
			notify({
				id: 'noPageToken_ShortTermDataApi',
				type: 'error',
				message: 'No page token found',
				description: 'No page token found for page number',
			});
			return;
		}

		setLoading(true);
		DataApiClient.fetchShortTermData<T>(historyType, pageToken, {
			userAccount: currentAccountPubKey,
			authority: currentAuthority.toString(),
			...(params ?? {}),
		})
			.then((res) => {
				const deserializedRecords = res.records.map((r) =>
					deserializer(r as unknown as Record<string, unknown>)
				);
				setRecords((prev) => {
					const newMap = new Map(prev);
					newMap.set(currentApiPage, deserializedRecords);
					return newMap;
				});
				if (res.meta.nextPage) {
					nextDataPageTokenTracker.addPageToken(
						currentApiPage + 1,
						res.meta.nextPage
					);
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [
		records,
		currentAccountPubKey,
		currentApiPage,
		historyType,
		stableParams,
		currentAuthority,
		enabled,
	]);

	const executePageChange = (
		uiPage: number,
		uiPageSize: number = SHORT_TERM_API_PAGE_SIZE
	) => {
		// Throw an error if the incoming pageSize isn't a perfect divisor of the PAGE_SIZE
		if (SHORT_TERM_API_PAGE_SIZE % uiPageSize !== 0) {
			throw new Error(
				`uiPageSize must be a perfect divisor of PAGE_SIZE(${SHORT_TERM_API_PAGE_SIZE})`
			);
		}

		// Convert UI page to API page
		const apiPage = Math.floor(
			(uiPage * uiPageSize) / SHORT_TERM_API_PAGE_SIZE
		);
		setCurrentUiPage(uiPage);
		setCurrentApiPage(apiPage);
	};

	const hasNextPage = nextDataPageTokenTracker.pageTokens.has(
		currentApiPage + 1
	);
	const hasLoadedNextPage =
		hasNextPage && records.get(currentApiPage + 1)?.length > 0;

	const allRecords = useMemo(() => {
		return Array.from(records.entries())
			.sort(([pageA], [pageB]) => pageA - pageB)
			.flatMap(([_, records]) => records);
	}, [records]);

	const knownMinRecords = hasLoadedNextPage
		? allRecords.length
		: allRecords.length + 1;

	return {
		currentPageRecords: records.get(currentApiPage) ?? DEFAULT_PAGE_RECORDS,
		allRecords,
		loading,
		executePageChange,
		hasNextPage,
		knownMinRecords,
	};
};
