import { useEffect, useState } from 'react';
import { FilterableHistoryType } from 'src/@types/historyTables';
import useHistoryTableFilterStore, {
	BaseFilterState,
	HistoryTableFilterStore,
} from 'src/stores/historyTableFilterStore/useHistoryTableFilterStore';
import useCurrentAuthority from '../useCurrentAuthority';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import DataApiClient from 'src/utils/DataApiClient';

const DEFAULT_RECORDS_LIST: any[] = [];

export const useLongTermDataApi = <T,>(
	historyType: FilterableHistoryType,
	deserializer: (r: Record<string, unknown>) => T,
	enabled: boolean
) => {
	const setHistoryFiltersStore = useHistoryTableFilterStore((s) => s.set);
	const resetHistoryFiltersStore = useHistoryTableFilterStore(
		(s) => s.resetFilterState
	);
	const historyFilters: BaseFilterState = useHistoryTableFilterStore((s) =>
		s.getFilterState(historyType)
	);
	const currentAccountPubKey = useDriftAccountStore((s) =>
		s.getCurrentUserAccount()?.pubKey?.toString()
	);
	const currentAuthority = useCurrentAuthority();

	const [records, setRecords] = useState<T[]>(DEFAULT_RECORDS_LIST);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!enabled) return;

		if (!currentAccountPubKey || !currentAuthority) {
			setRecords([]);
			return;
		}

		setLoading(true);

		DataApiClient.fetchLongTermData<T>(historyType, {
			userAccount: currentAccountPubKey,
			authority: currentAuthority.toString(),
			startDate: historyFilters.startDate,
			endDate: historyFilters.endDate,
		})
			.then((res) => {
				const deserializedRecords = res.records.map((r) =>
					deserializer(r as unknown as Record<string, unknown>)
				);
				setRecords(deserializedRecords);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [
		currentAccountPubKey,
		currentAuthority,
		enabled,
		historyFilters.startDate,
		historyFilters.endDate,
	]);

	const executePageChange = (newUiPage: number) => {
		setHistoryFiltersStore((s) => {
			// @ts-ignore
			s[historyType as keyof HistoryTableFilterStore].currentUiPage = newUiPage;
		});
	};

	useEffect(() => {
		return () => {
			resetHistoryFiltersStore(historyType);
		};
	}, []);

	return {
		allRecords: records,
		loading,
		executePageChange,
	};
};
