'use client';

import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import React, { ReactNode, useState } from 'react';
import {
	ChevronLeft,
	ChevronRight,
	ChevronsLeft,
	ChevronsRight,
} from '@drift-labs/icons';
import { useDebouncedCallback } from 'use-debounce';
import { notify } from 'src/utils/notifications';
import Button from './Button';
import PaginationInputPopup from './Popups/PaginationInputPopup';
import UI_UTILS from 'src/utils/uiUtils';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import { useDebounce } from 'react-use';

const Paginator = (props: {
	currentPage: number;
	pageSize: number;
	onPageChange: (newPage: number) => void;
	maxCount: number;
	noBorder?: boolean;
	leftChild?: ReactNode;
	fixedMaxPageIndex?: number;
	/**
	 * Token-based pagination allows the API server to keep track of where to continue fetching the next set of records.
	 * This means it can only go 1 page forward max, and can go backwards to any page that has been fetched (and token stored).
	 */
	isTokenBasedPagination?: boolean;
	disableRateLimitNotification?: boolean;
}) => {
	const maxPages =
		props.fixedMaxPageIndex ??
		UI_UTILS.getPageNumForCount(props.maxCount, props.pageSize);
	const maxPageIndex = maxPages - 1;

	const [firstPageIndexShown, setFirstPageIndexShown] = useState(0);
	const [showFromLast, setShowFromLast] = useState(false);
	const [debouncedCurrentPageIndex, setDebouncedCurrentPageIndex] = useState(
		props.currentPage
	);

	const isMobile = useIsMobileScreenSize();

	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen: setShowPaginationInput,
		isPopoverOpen: showPaginationInput,
	} = useTargetedPopover(
		{
			placement: 'top',
		},
		{
			offset: 16,
			disableAutoPlacement: true,
		}
	);

	const debouncedExecutePageChange = useDebouncedCallback(
		(newPage: number) => {
			if (newPage < 0) {
				setShowFromLast(true);
			}

			props.onPageChange(newPage);
		},
		500,
		{ leading: true }
	);

	const handlePageChange = (newPage: number) => {
		if (
			debouncedExecutePageChange.isPending() &&
			!props.disableRateLimitNotification
		) {
			notify({
				id: 'paginationRateLimit',
				type: 'info',
				message: 'Too many requests',
				description:
					"You can jump directly to a history page by clicking the '...' button",
			});
		}
		debouncedExecutePageChange(newPage);
	};

	useDebounce(
		// use debounce is to ensure both the page numbers in the paginator and the selected page number are updated at the same time
		() => {
			setDebouncedCurrentPageIndex(props.currentPage);

			if (props.isTokenBasedPagination) {
				if (props.currentPage > 0) {
					setFirstPageIndexShown(props.currentPage - 1);
				} else if (props.currentPage === maxPageIndex) {
					if (maxPageIndex > 1) {
						setFirstPageIndexShown(props.currentPage - 2);
					} else {
						setFirstPageIndexShown(props.currentPage);
					}
				} else if (props.currentPage === 0) {
					setFirstPageIndexShown(props.currentPage);
				}
			} else {
				const pageNumbersDisplayedInPaginator = [
					firstPageIndexShown,
					firstPageIndexShown + 1,
					firstPageIndexShown + 2,
				];

				if (
					(!pageNumbersDisplayedInPaginator.includes(props.currentPage) ||
						firstPageIndexShown > props.currentPage) &&
					props.currentPage !== maxPages
				) {
					setFirstPageIndexShown(props.currentPage);
				}
			}
		},
		10,
		[
			firstPageIndexShown,
			props.currentPage,
			maxPageIndex,
			props.isTokenBasedPagination,
		]
	);

	return (
		<>
			<div
				className={`px-1 md:px-4 py-1 flex justify-center md:justify-between text-neutrals-40 ${
					props.noBorder ? '' : 'border-t border-container-border'
				}`}
			>
				{props.leftChild ?? <span className="hidden md:block" />}

				<div className="md:mx-4 my-1 flex items-center h-[24px]">
					{/** Back to first page */}
					{!isMobile && (
						<Button.Ghost
							className="p-1 mx-1"
							size="SMALL"
							iconOnly
							roundedGradientBorder
							disabled={props.currentPage == 0}
							onClick={(e) => {
								if (props.currentPage == 0) return;
								e?.stopPropagation();
								handlePageChange(0);
							}}
						>
							<ChevronsLeft
								size={20}
								color={
									props.currentPage == 0
										? 'var(--text-disabled)'
										: 'var(--text-label)'
								}
							/>
						</Button.Ghost>
					)}
					{/** Previous page */}
					<Button.Ghost
						className="p-1 mx-1"
						size="SMALL"
						iconOnly
						roundedGradientBorder
						disabled={props.currentPage == 0}
						onClick={(e) => {
							if (props.currentPage == 0) return;
							e?.stopPropagation();
							handlePageChange(Math.max(0, props.currentPage - 1));
						}}
					>
						<ChevronLeft
							size={20}
							color={
								props.currentPage == 0
									? 'var(--text-disabled)'
									: 'var(--text-label)'
							}
						/>
					</Button.Ghost>
					<div className="flex items-center justify-center h-full font-numeral hover:cursor-pointer">
						{/** First Page Number */}
						<Button.Secondary
							className="mx-1 p-1 min-w-[28px]"
							size="SMALL"
							iconOnly
							roundedGradientBorder
							selected={debouncedCurrentPageIndex === firstPageIndexShown}
							onClick={(e) => {
								e?.stopPropagation();
								handlePageChange(firstPageIndexShown);
							}}
						>
							<div>{firstPageIndexShown + 1}</div>
						</Button.Secondary>
						{/** Second Page Number */}
						{maxPageIndex > 0 && (
							<Button.Secondary
								className="mx-1 p-1 min-w-[28px]"
								size="SMALL"
								iconOnly
								roundedGradientBorder
								selected={debouncedCurrentPageIndex === firstPageIndexShown + 1}
								onClick={(e) => {
									e?.stopPropagation();
									handlePageChange(firstPageIndexShown + 1);
								}}
							>
								<div>{firstPageIndexShown + 2}</div>
							</Button.Secondary>
						)}
						{/** Third Page Number */}
						{debouncedCurrentPageIndex < maxPageIndex &&
							!props.isTokenBasedPagination &&
							maxPageIndex > 1 && (
								<Button.Secondary
									className="mx-1 p-1 min-w-[28px]"
									size="SMALL"
									iconOnly
									roundedGradientBorder
									selected={
										debouncedCurrentPageIndex === firstPageIndexShown + 2
									}
									onClick={(e) => {
										e?.stopPropagation();
										handlePageChange(firstPageIndexShown + 2);
									}}
								>
									<div>{firstPageIndexShown + 3}</div>
								</Button.Secondary>
							)}
						{/** Ellipsis Button for manual page input */}
						{maxPages >= 6 && !props.isTokenBasedPagination && (
							<Button.Secondary
								className="mx-1 p-1 min-w-[28px]"
								size="SMALL"
								iconOnly
								roundedGradientBorder
								onClick={(e) => {
									e?.stopPropagation();
									setShowPaginationInput(true);
								}}
							>
								<div
									ref={refs.setReference}
									{...getReferenceProps()}
									className="select-none hover:cursor-pointer"
								>
									...
								</div>
							</Button.Secondary>
						)}
						{showFromLast && (
							<>
								{maxPageIndex > firstPageIndexShown + 3 && (
									<Button.Secondary
										className="mx-1 p-1 min-w-[28px]"
										size="SMALL"
										iconOnly
										roundedGradientBorder
										selected={props.currentPage === maxPages - 2}
										onClick={(e) => {
											e?.stopPropagation();
											handlePageChange(maxPages - 2);
										}}
									>
										<div>{maxPages - 1}</div>
									</Button.Secondary>
								)}
								{maxPages > firstPageIndexShown + 3 && (
									<Button.Secondary
										className="mx-1 p-1 min-w-[28px]"
										size="SMALL"
										iconOnly
										roundedGradientBorder
										selected={props.currentPage === maxPages - 1}
										onClick={(e) => {
											e?.stopPropagation();
											handlePageChange(maxPages - 1);
										}}
									>
										<div>{maxPages}</div>
									</Button.Secondary>
								)}
								{maxPages + 1 > firstPageIndexShown + 3 && (
									<Button.Secondary
										className="mx-1 p-1 min-w-[28px]"
										size="SMALL"
										iconOnly
										roundedGradientBorder
										selected={props.currentPage === maxPages}
										onClick={(e) => {
											e?.stopPropagation();
											handlePageChange(maxPages);
										}}
									>
										<div>{maxPages + 1}</div>
									</Button.Secondary>
								)}
							</>
						)}
					</div>
					{/** Next page */}
					<Button.Ghost
						className="p-1 mx-1"
						size="SMALL"
						iconOnly
						roundedGradientBorder
						disabled={props.currentPage >= maxPageIndex}
						onClick={(e) => {
							if (props.currentPage >= maxPageIndex) return;
							e?.stopPropagation();
							handlePageChange(Math.min(maxPages, props.currentPage + 1));
						}}
					>
						<ChevronRight
							size={20}
							color={
								props.currentPage >= maxPageIndex
									? 'var(--text-disabled)'
									: 'var(--text-label)'
							}
						/>
					</Button.Ghost>
					{/** To last page */}
					{!isMobile && !props.isTokenBasedPagination && (
						<Button.Ghost
							className="p-1 mx-1"
							size="SMALL"
							iconOnly
							roundedGradientBorder
							disabled={props.currentPage >= maxPageIndex}
							onClick={(e) => {
								if (props.currentPage >= maxPageIndex) return;
								e?.stopPropagation();
								handlePageChange(-1);
							}}
						>
							<ChevronsRight
								size={20}
								color={
									props.currentPage >= maxPageIndex
										? 'var(--text-disabled)'
										: 'var(--text-label)'
								}
							/>
						</Button.Ghost>
					)}
				</div>
			</div>

			{showPaginationInput && (
				<PaginationInputPopup
					setFloating={refs.setFloating}
					floatingStyles={floatingStyles}
					getFloatingProps={getFloatingProps}
					initialValue={props.currentPage + 1}
					maxValue={maxPages + 1}
					onClose={(newVal: number) => {
						setShowPaginationInput(false);

						if (newVal - 1 < 0) return;
						if (newVal - 1 > maxPages) return;

						if (newVal - 1 !== props.currentPage) handlePageChange(newVal - 1);
					}}
				/>
			)}
		</>
	);
};

export default Paginator;
