'use client';

import {
	BigNum,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	SpotBalanceType,
	StrictOraclePrice,
	ZERO,
	calculateAssetWeight,
	calculateLiabilityWeight,
	getWorstCaseTokenAmounts,
} from '@drift-labs/sdk';
import { COMMON_UI_UTILS, UIMarket, matchEnum } from '@drift/common';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Text from 'src/components/Text/Text';
import { PRIORITY_SPOT_MARKETS } from 'src/environmentVariables/EnvironmentVariables';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useGoToRoute from 'src/hooks/useGoToRoute';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import NumLib from 'src/utils/NumLib';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import useDriftActions from '../../hooks/useDriftActions';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import AssetBalanceCard from '../AssetBalanceCard';
import CheckboxInput from '../CheckboxInput';
import TableStateWrapper from '../TableStateWrapper';
import TableV2 from '../Tables/TableV2';
import BalanceRow from './BalanceRow';
import { twMerge } from 'tailwind-merge';
import { useShallow } from 'zustand/react/shallow';
import Checkbox from '../Checkbox';
import useBanksToRender from '../../hooks/useBanksToRender';
import UnsettledRow from './UnsettledRow';
import useHighlightedSpotMarkets from 'src/hooks/useHighlightedSpotMarkets';

export type UserBalanceInfo = {
	highlighted: boolean;
	spotMarketIndex: number;
	symbol: string;
	depositsBase: BigNum;
	depositsQuote: BigNum;
	borrowsBase: BigNum;
	borrowsQuote: BigNum;
	netBase: BigNum;
	netQuote: BigNum;
	liqPrice: BigNum;
	accountNum: number;
	assetWeight: BigNum;
	liabilityWeight: BigNum;
	defaultAssetWeight: number;
	defaultLiabilityWeight: number;
	isLoweredAssetWeight: boolean;
	scaleInitialAssetWeightStart: BigNum;
};

const UserBalancesPortfolioTable = (props: {
	expanded?: boolean;
	className?: string;
	hideSummary?: boolean;
	/**
	 * Show deposit button if user has no balances
	 */
	showDepositButtonWhenEmpty?: boolean;
	location?: 'trade-page' | 'overview-page';
}) => {
	const isMobile = useIsMobileScreenSize();
	const connected = useWalletIsConnected();

	const currentUserClient = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.client
	);
	const currentUserAccount =
		connected && currentUserClient?.isSubscribed
			? currentUserClient?.getUserAccount()
			: undefined;

	const poolId = currentUserAccount?.poolId || 0;
	const borrowLendData = useDriftStore((s) => s.borrowLendData);
	const [currentSettings, setCurrentSettings] = useCurrentSettings();
	const { showAccountValues, showZeroValues } = currentSettings;
	const currentUserId = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.userId ?? 0
	);
	const currentUserKey = COMMON_UI_UTILS.getUserKey(
		currentUserId,
		currentUserAccount?.authority
	);

	const spotPositions = useMemo(() => {
		return currentUserClient?.getActiveSpotPositions();
	}, [currentUserClient]);

	const accountBalances = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.spotBalances ?? []
	);
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();
	const router = useRouter();
	const pathname = usePathname();
	const goToRoute = useGoToRoute();
	const { switchMarket, showCollateralModal } = useDriftActions();
	const isOverviewPage = pathname === '/overview';
	const isBalancesPage = pathname === '/overview/balances';
	const isSwapPage = pathname.includes('swap');
	const [swapFromMarketIndex, swapToMarketIndex] = useDriftStore(
		useShallow((s) => [s.swap.fromMarketIndex, s.swap.toMarketIndex])
	);
	const needsSettlement = useDriftAccountStore(
		(s) =>
			s.getCurrentUserAccount()?.marginInfo?.marketsNeedingSettlement?.length >
			0
	);

	const [displayData, setDisplayData] = useState<UserBalanceInfo[]>(undefined);

	const headers = props.expanded
		? [
				'asset',
				'balance',
				'deposit/borrow APR',
				'liq. price',
				'asset/liability weight',
				'Action',
		  ]
		: ['asset', 'balance', 'deposit/borrow APR', 'liq. price', 'Action'];
	const tableGrid = props.expanded
		? `minmax(100px,6fr) minmax(160px,7fr) minmax(160px,5fr) minmax(90px,5fr) minmax(190px,6fr) minmax(164px,6fr)`
		: `minmax(100px,6fr) minmax(160px,7fr) ${
				isOverviewPage ? 'minmax(130px,3fr)' : 'minmax(160px,4fr)'
		  } minmax(90px,5fr) ${
				isOverviewPage && !needsSettlement
					? 'minmax(80px,2fr)'
					: 'minmax(215px,6fr)'
		  }`;

	const setShowZeroValues = (newValue: boolean) => {
		setCurrentSettings({
			...currentSettings,
			showZeroValues: newValue,
		});
	};

	const highlightedSpotMarkets = useHighlightedSpotMarkets();

	const shouldUseSpecialHighlighting = props.location === 'overview-page';
	const sortedBanks = useBanksToRender({
		poolId,
		sortBy: 'accountBalance',
		userKey: currentUserKey,
		opts: {
			highlightType: shouldUseSpecialHighlighting
				? 'forceDespiteBalance'
				: 'higherPriorityIfZeroBalance',
			highlightBanks: highlightedSpotMarkets,
			prioritySort: PRIORITY_SPOT_MARKETS,
		},
	});

	const updateDisplayData = () => {
		if (
			!driftClientIsReady ||
			!accountBalances ||
			!currentUserClient?.isSubscribed
		) {
			return;
		}

		const balancesByBank = sortedBanks.map((sortedBankInfo) => {
			const bank = sortedBankInfo.market;

			const borrowLendInfo = borrowLendData?.find(
				(mkt) => mkt.bankIndex === bank.marketIndex
			);

			const spotPosition = spotPositions.find(
				(pos) => pos.marketIndex === bank.marketIndex
			);
			const oraclePriceData = bank
				? driftClient.getOracleDataForSpotMarket(bank.marketIndex)
				: undefined;

			const strictOraclePrice = new StrictOraclePrice(
				oraclePriceData?.price ?? ZERO
			);

			const marketAccount = driftClient.getSpotMarketAccount(bank.marketIndex);

			const {
				weight: worstCaseWeightInitial,
				weightedTokenValue: weightedTokenValueInitial,
			} = spotPosition
				? getWorstCaseTokenAmounts(
						spotPosition,
						marketAccount,
						strictOraclePrice,
						'Initial',
						currentUserClient.getUserAccount().maxMarginRatio
				  )
				: { weight: null, weightedTokenValue: null };

			const allBalancesForThisSpotMarket = accountBalances.filter(
				(bal) => bal.asset.marketIndex === bank.marketIndex
			);

			const depositBalances = accountBalances.filter(
				(bal) =>
					bal.asset.marketIndex === bank.marketIndex &&
					matchEnum(bal.balanceType, SpotBalanceType.DEPOSIT)
			);
			const borrowBalances = accountBalances.filter(
				(bal) =>
					bal.asset.marketIndex === bank.marketIndex &&
					matchEnum(bal.balanceType, SpotBalanceType.BORROW)
			);
			const balancesSum = NumLib.sumBigNums(
				allBalancesForThisSpotMarket.map((bal) => bal.balance),
				bank.precisionExp
			);
			const depositsBase = NumLib.sumBigNums(
				depositBalances.map((depos) => depos.balance),
				bank.precisionExp
			);

			const depositsQuote = NumLib.sumBigNums(
				depositBalances.map((depos) => depos.quoteValue),
				QUOTE_PRECISION_EXP
			);
			const borrowsBase = NumLib.sumBigNums(
				borrowBalances.map((depos) => depos.balance),
				bank.precisionExp
			);
			const borrowsQuote = NumLib.sumBigNums(
				borrowBalances.map((depos) => depos.quoteValue),
				QUOTE_PRECISION_EXP
			);

			const oraclePriceBigNum = BigNum.from(
				strictOraclePrice.current,
				PRICE_PRECISION_EXP
			);

			const assetWeight =
				weightedTokenValueInitial !== null && weightedTokenValueInitial.gt(ZERO)
					? worstCaseWeightInitial
					: calculateAssetWeight(
							balancesSum.val,
							oraclePriceBigNum.val,
							marketAccount,
							'Initial'
					  );

			const liabilityWeight =
				weightedTokenValueInitial !== null && weightedTokenValueInitial.lt(ZERO)
					? worstCaseWeightInitial
					: calculateLiabilityWeight(balancesSum.val, marketAccount, 'Initial');

			const scaleInitialAssetWeightStartBigNum = BigNum.from(
				marketAccount.scaleInitialAssetWeightStart,
				QUOTE_PRECISION_EXP
			);
			const isLoweredAssetWeight = !scaleInitialAssetWeightStartBigNum.eqZero();

			return {
				highlighted: shouldUseSpecialHighlighting && sortedBankInfo.highlighted,
				spotMarketIndex: bank.marketIndex,
				symbol: bank.symbol,
				depositsBase,
				depositsQuote,
				borrowsBase,
				borrowsQuote,
				netBase: depositsBase.sub(borrowsBase),
				netQuote: depositsQuote.sub(borrowsQuote),
				liqPrice: BigNum.from(
					currentUserClient?.spotLiquidationPrice(bank.marketIndex),
					PRICE_PRECISION_EXP
				),
				accountNum: depositBalances.length + borrowBalances.length,
				assetWeight: BigNum.from(assetWeight),
				liabilityWeight: BigNum.from(liabilityWeight),
				defaultAssetWeight: borrowLendInfo.assetWeight,
				defaultLiabilityWeight: borrowLendInfo.liabilityWeight,
				isLoweredAssetWeight,
				scaleInitialAssetWeightStart: scaleInitialAssetWeightStartBigNum,
			};
		});

		const sortedBalancesByBank = [...balancesByBank].sort((a, b) => {
			const aHighligted = a.highlighted;
			const bHighligted = b.highlighted;

			if (aHighligted && !bHighligted) {
				return -1;
			} else if (!aHighligted && bHighligted) {
				return 1;
			}

			// sort borrows after non-zero deposits
			if (a.netQuote.isNeg() && b.netQuote.isNeg()) {
				// bigger borrows first
				return a.netQuote.gt(b.netQuote) ? 1 : -1;
			} else if (a.netQuote.isNeg()) {
				return b.netQuote.eqZero() ? -1 : a.netQuote.gt(b.netQuote) ? -1 : 1;
			} else if (b.netQuote.isNeg()) {
				return a.netQuote.eqZero() ? 1 : a.netQuote.gt(b.netQuote) ? -1 : 1;
			}
			return a.netQuote.eq(b.netQuote) ? 0 : a.netQuote.gt(b.netQuote) ? -1 : 1;
		});

		const nonZeroValues = sortedBalancesByBank.filter(
			(bal) => !bal.netBase.eqZero()
		);

		if (nonZeroValues.length > 0) {
			setDisplayData(showZeroValues ? sortedBalancesByBank : nonZeroValues);
		} else {
			setDisplayData([]);
		}
	};

	const handleSelection = useCallback(
		(marketIndex: number) => {
			const uiMarket = UIMarket.createSpotMarket(marketIndex);

			if (isSwapPage) {
				if (uiMarket.isUsdcMarket) {
					const isFromUsdc =
						UIMarket.createSpotMarket(swapFromMarketIndex).isUsdcMarket;
					const isToUsdc =
						UIMarket.createSpotMarket(swapToMarketIndex).isUsdcMarket;
					if (isFromUsdc || isToUsdc) {
						return;
					} else {
						// if either market is not USDC, and the user clicks on USDC, set swap from USDC market
						goToRoute(
							`/swap/USDC-${
								UIMarket.createSpotMarket(swapToMarketIndex).symbol
							}`
						);
					}
				} else {
					goToRoute(`/swap/USDC-${uiMarket.market.symbol}`);
				}
			} else {
				if (uiMarket.isUsdcMarket) {
					return;
				}
				switchMarket({
					marketIndex: uiMarket.market.marketIndex,
					marketType: uiMarket.marketType,
				});
				goToRoute(`/${uiMarket.baseAssetSymbol()}`);
			}
		},
		[router, isSwapPage, goToRoute, swapFromMarketIndex, swapToMarketIndex]
	);

	const handleClickDepositButton = useCallback(() => {
		showCollateralModal('deposit', {
			defaultToBiggestAsset: true,
		});
	}, [currentUserKey]);

	useEffect(() => {
		updateDisplayData();
	}, [
		driftClientIsReady,
		accountBalances,
		currentUserAccount?.totalDeposits?.toString(),
		currentUserAccount?.totalWithdraws?.toString(),
		showZeroValues,
	]);

	return (
		<>
			<TableStateWrapper
				records={displayData}
				emptyStateText={'No balances found'}
				requireWalletConnect
				requireAccountCreated
				id="user_balances_portfolio_table"
				showDepositButtonWhenEmpty={props.showDepositButtonWhenEmpty}
				handleClickDepositButton={handleClickDepositButton}
				className="overflow-x-auto thin-scroll"
			>
				{isMobile ? (
					<>
						<div
							className={twMerge(
								'px-6 py-4 pb-0',
								(isOverviewPage || isBalancesPage) && 'pb-4'
							)}
						>
							<button
								onClick={() => setShowZeroValues(!showZeroValues)}
								className="flex flex-row items-center space-x-2 text-text-label"
							>
								<Checkbox checked={showZeroValues} />
								<Text.BODY2>Show Zero Values</Text.BODY2>
							</button>
						</div>
						<div className={`divide-container-border ${props.className}`}>
							{connected && (
								<UnsettledRow className="w-full p-3 pt-2 pr-2 border-t text-text-label border-container-border" />
							)}
							{displayData &&
								displayData.map((spotMarket, index) => (
									<AssetBalanceCard
										userBalanceInfo={spotMarket}
										key={`${index}_${spotMarket.symbol}`}
									/>
								))}
						</div>
					</>
				) : (
					<TableV2.Skeleton
						noBorder
						top={
							<TableV2.HeaderRow
								grid={tableGrid}
								header
								forceBottomBorder
								className={`w-full grid text-xs pr-2`}
							>
								{headers.map((label) => {
									if (label === 'deposit/borrow APR') {
										return (
											<TableV2.HeaderCell
												key={`header_${label}`.replace(/ /g, '')}
												className={twMerge(
													'capitalize',
													!props.expanded && 'bg-main-bg'
												)}
											>
												<div
													className={twMerge(
														isOverviewPage && 'flex flex-col items-start'
													)}
												>
													<span>Deposit/</span>
													<span>Borrow APR</span>
												</div>
											</TableV2.HeaderCell>
										);
									}

									return (
										<TableV2.HeaderCell
											key={`header_${label}`.replace(/ /g, '')}
											className={twMerge(
												'capitalize',
												!props.expanded && 'bg-main-bg'
											)}
										>
											{label}
										</TableV2.HeaderCell>
									);
								})}
							</TableV2.HeaderRow>
						}
						middle={
							<div
								className={`h-full w-full text-xs thin-scroll ${props.className}`}
							>
								{connected && (
									<UnsettledRow
										tableGrid={tableGrid}
										expanded={props.expanded}
									/>
								)}

								{displayData?.map((spotMarket, index) => (
									<BalanceRow
										highlighted={spotMarket.highlighted}
										key={spotMarket.spotMarketIndex}
										className={props.className}
										tableGrid={tableGrid}
										expanded={props.expanded}
										spotMarket={spotMarket}
										index={index}
										handleSelection={handleSelection}
										isOverviewPage={isOverviewPage}
									/>
								))}
							</div>
						}
						bottom={
							!props.hideSummary && (
								<TableV2.SummaryRow
									className={'border-t border-container-border'}
									grid={tableGrid}
								>
									<TableV2.BodyCell className="items-center border-b-0 text-text-default">
										<Text.BODY1 className="pt-0.5">TOTAL</Text.BODY1>
									</TableV2.BodyCell>
									<TableV2.NotionalCell
										value={
											displayData
												? NumLib.sumBigNums(
														displayData.map((row) => row?.netQuote),
														QUOTE_PRECISION_EXP
												  )
												: BigNum.zero()
										}
										toFixed={2}
										textOverride={!showAccountValues ? '*****' : undefined}
										className="items-center border-b-0 text-text-default"
									></TableV2.NotionalCell>
									{props.expanded ? (
										<>
											<div></div>
											<div></div>
											<div></div>
										</>
									) : (
										<>
											<div></div>
											<div></div>
										</>
									)}
									<TableV2.BodyCell className="items-center justify-end border-b-0 text-text-default">
										<CheckboxInput
											className="whitespace-nowrap"
											label="Show Zero Values"
											checked={showZeroValues}
											onChange={() => setShowZeroValues(!showZeroValues)}
											secondaryStyle
										/>
									</TableV2.BodyCell>
								</TableV2.SummaryRow>
							)
						}
					/>
				)}
			</TableStateWrapper>
		</>
	);
};

export default UserBalancesPortfolioTable;
