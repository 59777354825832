'use client';

import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { notify } from 'src/utils/notifications';
import Paginator from './Paginator';
import TableV2 from './Tables/TableV2';
import TableStateWrapper, { TableStateProps } from './TableStateWrapper';
import { JSX } from 'react';

export type PaginatedTableProps = {
	tableGrid: string;
	pageSize: number;
	currentPage: number;
	totalItems: number;
	tableContentTop: JSX.Element;
	tableContentMiddle: JSX.Element;
	pageChangeCallback: (newPage: number) => void;
	hideHeadersOnMobile?: boolean;
	tableContentBottom?: JSX.Element;
	noBorder?: boolean;
	innerClassName?: string;
	fillSpace?: boolean;
	tableState: TableStateProps;
	setIsScrollable?: (isScrollable: boolean) => void;
	notRounded?: boolean;
	fixedMaxPageIndex?: number;
	/**
	 * Token-based pagination allows the API server to keep track of where to continue fetching the next set of records.
	 * This means it can only go 1 page forward max, and can go backwards to any page that has been fetched (and token stored).
	 */
	isTokenBasedPagination?: boolean;
	disableRateLimitNotification?: boolean;
};

const PaginatedTableV2 = (props: PaginatedTableProps) => {
	const debouncedExecutePageChange = useDebouncedCallback(
		(newPage: number) => {
			props.pageChangeCallback(newPage);
		},
		500,
		{ leading: true }
	);

	const handlePageChange = (newPage: number) => {
		if (
			debouncedExecutePageChange.isPending() &&
			!props.disableRateLimitNotification
		) {
			notify({
				id: 'paginationRateLimit',
				type: 'info',
				message: 'Too many requests',
				description:
					"You can jump directly to a history page by clicking the '...' button",
			});
		}
		debouncedExecutePageChange(newPage);
	};

	const paginator =
		props.totalItems > props.pageSize ? (
			<Paginator
				pageSize={props.pageSize}
				currentPage={props.currentPage}
				onPageChange={handlePageChange}
				maxCount={props.totalItems}
				leftChild={props.tableContentBottom ?? null}
				fixedMaxPageIndex={props.fixedMaxPageIndex}
				isTokenBasedPagination={props.isTokenBasedPagination}
				disableRateLimitNotification={props.disableRateLimitNotification}
			/>
		) : (
			props.tableContentBottom ?? null
		);

	return (
		<TableStateWrapper {...props.tableState} paginator={paginator}>
			<TableV2.Skeleton
				top={props.tableContentTop}
				middle={props.tableContentMiddle}
				hideHeadersOnMobile={props.hideHeadersOnMobile}
				noBorder={props.noBorder}
				innerClassName={props.innerClassName}
				fillSpace={props.fillSpace}
				notRounded={props.notRounded}
				setIsScrollable={props.setIsScrollable}
			/>
		</TableStateWrapper>
	);
};

export default PaginatedTableV2;
