import { Dayjs } from 'dayjs';
import { diff } from 'deep-object-diff';
import produce from 'immer';
import { FilterableHistoryType, HistoryType } from 'src/@types/historyTables';
import { create } from 'zustand';
import { DEFAULT_DATE_RANGE } from '../../constants/historyTables';
import { dlog } from '../../dev';
import { safeCheckDevSwitchIsOn } from '../../hooks/useDevSwitchIsOn';

type FilterMode = 'SHORT_TERM' | 'LONG_TERM'; // SHORT_TERM = <= 31 days, LONG_TERM = any duration

export type BaseFilterState = {
	currentUiPage: number; // UI and data page are the same for short term data; data page management is handled separately for long term data
	startDate: Dayjs | null;
	endDate: Dayjs | null;
	defaultStartDate: Dayjs;
	defaultEndDate: Dayjs;
	getFilterMode: () => FilterMode;
	isLive?: boolean; // Optional isLive flag that will be added when requested with context
};

// Define the filter state types for each history type
type DepositsFilterState = {
	spotMarketSymbol: string | 'all_markets'; // undefined means all spot markets
	type: 'deposit' | 'withdraw' | 'all';
} & BaseFilterState;

type OrdersFilterState = {
	marketSymbol: string | 'all_markets';
} & BaseFilterState;

type TradesFilterState = {
	marketSymbol: string | 'all_markets';
} & BaseFilterState;

type PredictionTradesFilterState = {
	marketSymbol: string | 'all_markets';
} & BaseFilterState;

type FundingPaymentsFilterState = {
	marketSymbol: string | 'all_markets';
	direction: 'all' | 'long' | 'short';
} & BaseFilterState;

type SwapsFilterState = BaseFilterState;

type SettledBalancesFilterState = {
	marketSymbol: string | 'all_markets';
} & BaseFilterState;

type BalFilterState = {
	marketSymbol: string | 'all_markets';
	action: 'all' | string;
} & BaseFilterState;

type IfStakingFilterState = {
	spotMarketSymbol: string | 'all_markets';
} & BaseFilterState;

type LiquidationsFilterState = BaseFilterState;

// Map history types to their filter state types
type FilterStateMap = {
	deposits: DepositsFilterState;
	orders: OrdersFilterState;
	trades: TradesFilterState;
	predictionTrades: PredictionTradesFilterState;
	'funding-payments': FundingPaymentsFilterState;
	swaps: SwapsFilterState;
	'settled-balances': SettledBalancesFilterState;
	bal: BalFilterState;
	'if-staking': IfStakingFilterState;
	liquidations: LiquidationsFilterState;
};

// The internal store type with direct property access
export type HistoryTableFilterStoreKey = keyof HistoryTableFilterStore;
export type HistoryTableFilterStoreValueType =
	HistoryTableFilterStore[HistoryTableFilterStoreKey];

export type HistoryTableFilterStore = {
	set: (x: (s: HistoryTableFilterStore) => void) => void;
	get: () => HistoryTableFilterStore;
	getFilterState: <T extends FilterableHistoryType>(
		historyType: T
	) => FilterStateMap[T];
	resetFilterState: <T extends FilterableHistoryType>(historyType: T) => void;
	deposits: FilterStateMap['deposits'];
	orders: FilterStateMap['orders'];
	trades: FilterStateMap['trades'];
	predictionTrades: FilterStateMap['predictionTrades'];
	['funding-payments']: FilterStateMap['funding-payments'];
	swaps: FilterStateMap['swaps'];
	['settled-balances']: FilterStateMap['settled-balances'];
	bal: FilterStateMap['bal'];
	['if-staking']: FilterStateMap['if-staking'];
	liquidations: FilterStateMap['liquidations'];
};

const BASE_FILTER_STATE: BaseFilterState = {
	currentUiPage: 0,
	startDate: null,
	endDate: null,
	defaultStartDate: DEFAULT_DATE_RANGE.startDate,
	defaultEndDate: DEFAULT_DATE_RANGE.endDate,
	getFilterMode: () => 'SHORT_TERM' as FilterMode,
};

/**
 * As long as any of filters are not the default, we use the long term data API.
 * Reason being - e.g. the short term data API only returns page by page of all market data.
 * If user selects BTC-PERP as the filter, the first UI page will most likely show incomplete data (unless the entire page is BTC-PERP's data).
 * Hence using long term data API (which returns up to 5000 records) is more reliable to display.
 */
const genericGetFilterMode =
	(
		defaultConfigs: Record<string, any>,
		historyStoreGetter: () => HistoryTableFilterStore,
		historyKey: HistoryType
	) =>
	() => {
		const specificHistoryStore = historyStoreGetter().getFilterState(
			historyKey as FilterableHistoryType
		);

		if (!specificHistoryStore) {
			return 'SHORT_TERM';
		}

		// If isLive is true, always use SHORT_TERM mode for live data
		if (specificHistoryStore.isLive) {
			return 'SHORT_TERM';
		}

		for (const [key, defaultValue] of Object.entries(defaultConfigs)) {
			// @ts-ignore
			if (specificHistoryStore[key] !== defaultValue) {
				return 'LONG_TERM';
			}
		}

		const hasDateRangeFilter =
			specificHistoryStore.startDate !== null ||
			specificHistoryStore.endDate !== null;

		if (!hasDateRangeFilter) {
			return 'SHORT_TERM';
		}

		return 'LONG_TERM';
	};

const useHistoryTableFilterStore = create<HistoryTableFilterStore>(
	(set, get): HistoryTableFilterStore => {
		const immerSet = (fn: (s: HistoryTableFilterStore) => void) => {
			if (safeCheckDevSwitchIsOn()) {
				const newState = produce(fn)(get());
				dlog(
					`history_tables_v2`,
					`new_history_filter_store_state_diff`,
					diff(get(), newState)
				);
				set(newState);
			} else {
				return set(produce(fn));
			}
		};

		return {
			set: immerSet,
			get: () => get(),
			// Add the getFilterState method with context parameter
			getFilterState: <T extends FilterableHistoryType>(
				historyType: T
			): FilterStateMap[T] => {
				const fullFilterState = get();
				const baseFilterState = fullFilterState[
					historyType
				] as FilterStateMap[T];

				return baseFilterState;
			},
			resetFilterState: <T extends FilterableHistoryType>(historyType: T) => {
				immerSet((s) => {
					s[historyType].endDate = null;
					s[historyType].startDate = null;
					s[historyType].currentUiPage = 0;
				});
			},
			deposits: {
				...BASE_FILTER_STATE,
				spotMarketSymbol: 'all_markets',
				type: 'all',
				getFilterMode: genericGetFilterMode(
					{
						spotMarketSymbol: 'all_markets',
						type: 'all',
					},
					get,
					'deposits'
				),
			},
			orders: {
				...BASE_FILTER_STATE,
				marketSymbol: 'all_markets',
				getFilterMode: () => 'SHORT_TERM', // no long term data API for orders history
			},
			trades: {
				...BASE_FILTER_STATE,
				marketSymbol: 'all_markets',
				getFilterMode: genericGetFilterMode(
					{
						marketSymbol: 'all_markets',
					},
					get,
					'trades'
				),
			},
			predictionTrades: {
				...BASE_FILTER_STATE,
				marketSymbol: 'all_markets',
				getFilterMode: genericGetFilterMode(
					{
						marketSymbol: 'all_markets',
					},
					get,
					'predictionTrades'
				),
			},
			['funding-payments']: {
				...BASE_FILTER_STATE,
				marketSymbol: 'all_markets',
				direction: 'all',
				getFilterMode: genericGetFilterMode(
					{
						marketSymbol: 'all_markets',
						direction: 'all',
					},
					get,
					'funding-payments'
				),
			},
			swaps: {
				...BASE_FILTER_STATE,
				getFilterMode: genericGetFilterMode({}, get, 'swaps'),
			},
			['settled-balances']: {
				...BASE_FILTER_STATE,
				marketSymbol: 'all_markets',
				getFilterMode: genericGetFilterMode(
					{
						marketSymbol: 'all_markets',
					},
					get,
					'settled-balances'
				),
			},
			bal: {
				...BASE_FILTER_STATE,
				marketSymbol: 'all_markets',
				action: 'all',
				getFilterMode: genericGetFilterMode(
					{
						marketSymbol: 'all_markets',
						action: 'all',
					},
					get,
					'bal'
				),
			},
			['if-staking']: {
				...BASE_FILTER_STATE,
				spotMarketSymbol: 'all_markets',
				getFilterMode: genericGetFilterMode(
					{
						spotMarketSymbol: 'all_markets',
					},
					get,
					'if-staking'
				),
			},
			liquidations: {
				...BASE_FILTER_STATE,
				getFilterMode: genericGetFilterMode({}, get, 'liquidations'),
			},
		};
	}
);

export default useHistoryTableFilterStore;
