'use client';

import useSafePush from 'src/hooks/useSafePush';
import Text from '../Text/Text';
import { UserBalanceInfo } from './UserBalancesPortfolioTable';
import PopoverWrapper from '../PopoverWrapper';
import useDriftActions from '../../hooks/useDriftActions';
import { ALLOW_TRANSFER_FOR_NON_MAIN_POOLS } from '../../constants/constants';

const BalanceActionsPopup = (props: {
	targetElementId: string;
	onClose: () => void;
	market: UserBalanceInfo;
	isOnBorrow: boolean;
	setFloating: (node: HTMLElement) => void;
	floatingStyles: React.CSSProperties;
	getFloatingProps: () => Record<string, unknown>;
}) => {
	const safePush = useSafePush();
	const showCollateralModal = useDriftActions().showCollateralModal;

	const getSwapRoute = () => {
		// USDC
		if (props.market.spotMarketIndex === 0) {
			return 'SOL-USDC';
		} else {
			return `USDC-${props.market.symbol}`;
		}
	};

	const BALANCE_ACTIONS_OPTIONS = [
		{
			label: 'Deposit',
			action: () => {
				showCollateralModal('deposit', {
					bankIndex: props.market.spotMarketIndex,
				});
			},
		},
		{
			label: 'Swap',
			action: () => safePush(`/swap/${getSwapRoute()}`),
		},
		{
			label: 'Borrow',
			action: () => {
				showCollateralModal('borrow', {
					bankIndex: props.market.spotMarketIndex,
				});
			},
		},
		{
			label: 'Withdraw',
			action: () => {
				showCollateralModal('withdraw', {
					bankIndex: props.market.spotMarketIndex,
				});
			},
		},
		{
			label: 'Transfer',
			action: () => {
				showCollateralModal('transfer', {
					bankIndex: props.market.spotMarketIndex,
				});
			},
		},
	].filter((option) =>
		ALLOW_TRANSFER_FOR_NON_MAIN_POOLS ? true : option.label !== 'Transfer'
	);

	const BALANCE_ACTIONS_OPTIONS_ON_BORROW = [
		{
			label: 'Repay',
			action: () => {
				showCollateralModal('deposit', {
					bankIndex: props.market.spotMarketIndex,
				});
			},
		},
		{
			label: 'Swap',
			action: () => safePush(`/swap/${getSwapRoute()}`),
		},
	];

	const optionsToRender = props.isOnBorrow
		? BALANCE_ACTIONS_OPTIONS_ON_BORROW
		: BALANCE_ACTIONS_OPTIONS;

	const renderOptions = () => {
		return optionsToRender.map((option, index) => {
			return (
				<Text.BODY1
					className="p-2 cursor-pointer text-text-emphasis hover:bg-container-bg-hover"
					key={index}
					onClick={() => {
						option.action();
						props.onClose();
					}}
				>
					{option.label}
				</Text.BODY1>
			);
		});
	};

	return (
		<PopoverWrapper
			ref={props.setFloating}
			style={props.floatingStyles}
			{...props.getFloatingProps()}
		>
			<div className="flex flex-col divide-y rounded bg-container-bg divide-container-border">
				{renderOptions()}
			</div>
		</PopoverWrapper>
	);
};

export default BalanceActionsPopup;
