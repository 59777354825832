import { BN, MarketType } from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	UISerializableDepositRecord,
	UISerializableFundingPaymentRecord,
	UISerializableLiquidationRecord,
	UISerializableLPRecord,
	UISerializableOrderActionRecord,
	UISerializableOrderRecordV2,
	UISerializableSettlePnlRecord,
} from '@drift/common';
import dayjs from 'dayjs';
import { SyncHistoryParams } from 'src/@types/historyTables';
import { ParentOrderInfo } from 'src/components/OrderHistoryTable/ParentOrderRow';
import { ZERO_QUOTE_BIGNUM } from 'src/constants/math';
import {
	PERP_MARKETS_LOOKUP,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import {
	BaseFilterState,
	HistoryTableFilterStore,
} from 'src/stores/historyTableFilterStore/useHistoryTableFilterStore';
import { DriftAccountsStore } from 'src/stores/useDriftAccountsStore';

export type HistoryTableFilterContext = {
	isLive?: boolean;
};

export const getLiquidationHistoryTableSync = (
	setAccountsStore: DriftAccountsStore['set'],
	params: SyncHistoryParams<'liquidationHistory'>
) => {
	const { userKey, history, totalCount, loading } = params;

	const formattedV1LiquidationHistory: UISerializableLiquidationRecord[] =
		history.map((v2Record) => ({
			liquidationId: v2Record.liquidationId,
			txSig: v2Record.txSig,
			txSigIndex: v2Record.txSigIndex,
			slot: v2Record.slot,
			ts: v2Record.ts,
			user: v2Record.user,
			liquidator: v2Record.liquidator,
			marginRequirement: v2Record.marginRequirement,
			totalCollateral: v2Record.totalCollateral,
			liquidationType: v2Record.liquidationType,
			liquidatePerp: {
				marketIndex: v2Record.liquidatePerp_marketIndex,
				orderIds: [] as BN[], // missing from v2, but seems like not used on UI anyway
				oraclePrice: v2Record.liquidatePerp_oraclePrice,
				baseAssetAmount: v2Record.liquidatePerp_baseAssetAmount,
				quoteAssetAmount: v2Record.liquidatePerp_quoteAssetAmount,
				userPnl: ZERO_QUOTE_BIGNUM, // missing from v2, but seems like not used on UI anyway
				liquidatorPnl: ZERO_QUOTE_BIGNUM, // missing from v2, but seems like not used on UI anyway
				canceledOrdersFee: ZERO_QUOTE_BIGNUM, // missing from v2, but seems like not used on UI anyway
				userOrderId: v2Record.liquidatePerp_userOrderId,
				liquidatorOrderId: v2Record.liquidatePerp_liquidatorOrderId,
				fillRecordId: v2Record.liquidatePerp_fillRecordId,
				lpShares: v2Record.liquidatePerp_lpShares,
				liquidatorFee: v2Record.liquidatePerp_liquidatorFee,
				ifFee: v2Record.liquidatePerp_ifFee,
			},
			liquidateSpot: {
				assetMarketIndex: v2Record.liquidateSpot_assetMarketIndex,
				assetPrice: v2Record.liquidateSpot_assetPrice,
				assetTransfer: v2Record.liquidateSpot_assetTransfer,
				liabilityMarketIndex: v2Record.liquidateSpot_liabilityMarketIndex,
				liabilityPrice: v2Record.liquidateSpot_liabilityPrice,
				liabilityTransfer: v2Record.liquidateSpot_liabilityTransfer,
				ifFee: v2Record.liquidateSpot_ifFee.val,
			},
			liquidateBorrowForPerpPnl: {
				marketOraclePrice: v2Record.liquidateBorrowForPerpPnl_marketOraclePrice,
				pnlTransfer: v2Record.liquidateBorrowForPerpPnl_pnlTransfer,
				liabilityMarketIndex:
					v2Record.liquidateBorrowForPerpPnl_liabilityMarketIndex,
				liabilityPrice: v2Record.liquidateBorrowForPerpPnl_liabilityPrice,
				liabilityTransfer: v2Record.liquidateBorrowForPerpPnl_liabilityTransfer,
				perpMarketIndex: v2Record.liquidateBorrowForPerpPnl_perpMarketIndex,
			},
			liquidatePerpPnlForDeposit: {
				marketOraclePrice:
					v2Record.liquidatePerpPnlForDeposit_marketOraclePrice,
				pnlTransfer: v2Record.liquidatePerpPnlForDeposit_pnlTransfer,
				assetMarketIndex: v2Record.liquidatePerpPnlForDeposit_assetMarketIndex,
				assetPrice: v2Record.liquidatePerpPnlForDeposit_assetPrice,
				assetTransfer: v2Record.liquidatePerpPnlForDeposit_assetTransfer,
				perpMarketIndex: v2Record.liquidatePerpPnlForDeposit_perpMarketIndex,
			},
			perpBankruptcy: {
				marketIndex: v2Record.perpBankruptcy_marketIndex,
				pnl: v2Record.perpBankruptcy_pnl.val,
				ifPayment: v2Record.perpBankruptcy_ifPayment.val,
				clawbackUser: v2Record.perpBankruptcy_clawbackUser,
				clawbackUserPayment: v2Record.perpBankruptcy_clawbackUserPayment.val,
				cumulativeFundingRateDelta:
					v2Record.perpBankruptcy_cumulativeFundingRateDelta.val,
			},
			spotBankruptcy: {
				marketIndex: v2Record.spotBankruptcy_marketIndex,
				borrowAmount: v2Record.spotBankruptcy_borrowAmount.val,
				cumulativeDepositInterestDelta:
					v2Record.spotBankruptcy_cumulativeDepositInterestDelta.val,
				ifPayment: v2Record.spotBankruptcy_ifPayment.val,
			},
			canceledOrderIds: v2Record.canceledOrderIds,
			marginFreed: v2Record.marginFreed,
			bankrupt: v2Record.bankrupt,
		}));

	setAccountsStore((s) => {
		s.accounts[userKey]['liquidationHistory'] = {
			records: formattedV1LiquidationHistory,
			totalCount,
			loading,
			initialHistoryLoaded: true,
		};
	});
};

export const convertV2OrderRecordToParentOrderInfo = (
	record: UISerializableOrderRecordV2
): ParentOrderInfo => {
	return {
		ts: record.ts,
		slot: record.slot,
		orderType: record.orderType,
		marketType: record.marketType,
		orderId: record.orderId,
		marketIndex: record.marketIndex,
		price: record.price,
		baseAssetAmount: record.baseAssetAmount,
		baseAssetAmountFilled: record.baseAssetAmountFilled,
		quoteAssetAmountFilled: record.quoteAssetAmountFilled,
		direction: record.direction,
		reduceOnly: record.reduceOnly,
		triggerPrice: record.triggerPrice,
		triggerCondition: record.triggerCondition,
		existingPositionDirection: record.existingPositionDirection,
		postOnly: record.postOnly,
		immediateOrCancel: record.immediateOrCancel,
		oraclePriceOffset: record.oraclePriceOffset,
		lastUpdatedTs: record.lastUpdatedTs,
		lastActionExplanation: record.lastActionExplanation,
		lastActionStatus: record.lastActionStatus,
	};
};

const checkDateRange = (
	record: { ts: BN },
	filterState: BaseFilterState,
	context?: HistoryTableFilterContext
) => {
	const isLive = context?.isLive;

	if (isLive) {
		return true;
	} // Don't apply date range filters to the live data

	return (
		(filterState.startDate === null
			? true
			: dayjs.unix(record.ts.toNumber()).isAfter(filterState.startDate)) &&
		(filterState.endDate === null
			? true
			: dayjs.unix(record.ts.toNumber()).isBefore(filterState.endDate))
	);
};

export const getDepositHistoryFilter = (
	record: UISerializableDepositRecord,
	filterState: HistoryTableFilterStore['deposits']
) => {
	const matchesDirectionFilter =
		filterState.type === 'all' ||
		ENUM_UTILS.toStr(record.direction) === filterState.type;
	const matchesSpotMarketIndexFilter =
		filterState.spotMarketSymbol === 'all_markets' ||
		SPOT_MARKETS_LOOKUP[record.marketIndex].symbol ===
			filterState.spotMarketSymbol;

	const matchesDateRangeFilter = checkDateRange(record, filterState);

	return (
		matchesDirectionFilter &&
		matchesSpotMarketIndexFilter &&
		matchesDateRangeFilter
	);
};

export const getTradesHistoryFilter = (
	record: UISerializableOrderActionRecord,
	filterState: HistoryTableFilterStore['trades']
) => {
	const marketConfig = ENUM_UTILS.match(record.marketType, MarketType.PERP)
		? PERP_MARKETS_LOOKUP[record.marketIndex]
		: SPOT_MARKETS_LOOKUP[record.marketIndex];
	const matchesMarketSymbolFilter =
		filterState.marketSymbol === 'all_markets' ||
		marketConfig.symbol === filterState.marketSymbol;

	const matchesDateRangeFilter = checkDateRange(record, filterState);

	return matchesMarketSymbolFilter && matchesDateRangeFilter;
};

export const getPredictionsHistoryFilter = (
	record: UISerializableOrderActionRecord,
	filterState: HistoryTableFilterStore['predictionTrades']
) => {
	return getTradesHistoryFilter(record, filterState);
};

export const orderHistoryFilter = (
	record: ParentOrderInfo,
	filterState: HistoryTableFilterStore['orders']
) => {
	const marketConfig = ENUM_UTILS.match(record.marketType, MarketType.PERP)
		? PERP_MARKETS_LOOKUP[record.marketIndex]
		: SPOT_MARKETS_LOOKUP[record.marketIndex];
	const matchesMarketSymbolFilter =
		filterState.marketSymbol === 'all_markets' ||
		marketConfig.symbol === filterState.marketSymbol;

	return matchesMarketSymbolFilter;
};

export const getFundingHistoryFilter = (
	record: UISerializableFundingPaymentRecord,
	filterState: HistoryTableFilterStore['funding-payments']
) => {
	const marketConfig = PERP_MARKETS_LOOKUP[record.marketIndex];
	const matchesMarketSymbolFilter =
		filterState.marketSymbol === 'all_markets' ||
		marketConfig.symbol === filterState.marketSymbol;

	const matchesDirectionFilter =
		filterState.direction === 'all' ||
		(record.baseAssetAmount.isNeg() && filterState.direction === 'short') ||
		(record.baseAssetAmount.isPos() && filterState.direction === 'long');

	const matchesDateRangeFilter = checkDateRange(record, filterState);

	return (
		matchesMarketSymbolFilter &&
		matchesDirectionFilter &&
		matchesDateRangeFilter
	);
};

export const getIfStakingHistoryFilter = (
	record: UISerializableFundingPaymentRecord,
	filterState: HistoryTableFilterStore['if-staking']
) => {
	const marketConfig = SPOT_MARKETS_LOOKUP[record.marketIndex];
	const matchesMarketSymbolFilter =
		filterState.spotMarketSymbol === 'all_markets' ||
		marketConfig.symbol === filterState.spotMarketSymbol;

	const matchesDateRangeFilter = checkDateRange(record, filterState);

	return matchesMarketSymbolFilter && matchesDateRangeFilter;
};

export const getSettledBalancesHistoryFilter = (
	record: UISerializableSettlePnlRecord,
	filterState: HistoryTableFilterStore['settled-balances']
) => {
	const marketConfig = PERP_MARKETS_LOOKUP[record.marketIndex];
	const matchesMarketSymbolFilter =
		filterState.marketSymbol === 'all_markets' ||
		marketConfig.symbol === filterState.marketSymbol;

	const matchesDateRangeFilter = checkDateRange(record, filterState);

	return matchesMarketSymbolFilter && matchesDateRangeFilter;
};

export const getBalHistoryFilter = (
	record: UISerializableLPRecord,
	filterState: HistoryTableFilterStore['bal']
) => {
	const marketConfig = PERP_MARKETS_LOOKUP[record.marketIndex];
	const matchesMarketSymbolFilter =
		filterState.marketSymbol === 'all_markets' ||
		marketConfig.symbol === filterState.marketSymbol;

	const matchesActionFilter =
		filterState.action === 'all' ||
		ENUM_UTILS.toStr(record.action) === filterState.action;

	const matchesDateRangeFilter = checkDateRange(record, filterState);

	return (
		matchesMarketSymbolFilter && matchesActionFilter && matchesDateRangeFilter
	);
};
