import { Serializer } from '@drift/common';
import {
	FilterableHistoryType,
	HistoryKeyType,
	HistoryRecordType,
	HistoryType,
	SyncHistoryParams,
} from 'src/@types/historyTables';
import useHistoryTableFilterStore, {
	HistoryTableFilterStore,
} from '../stores/historyTableFilterStore/useHistoryTableFilterStore';
import { useShortTermDataApi } from './historyData/useShortTermDataApi';
import useCurrentUserAccount from './useCurrentUserAccount';
import { useEffect } from 'react';
import useDriftAccountsStore, {
	DriftAccountsStore,
	HistoryBaseState,
	HistoryBaseStateWithMaxRecordLimit,
} from '../stores/useDriftAccountsStore';
import {
	convertV2OrderRecordToParentOrderInfo,
	getDepositHistoryFilter,
	getLiquidationHistoryTableSync,
	getPredictionsHistoryFilter,
	getTradesHistoryFilter,
	getFundingHistoryFilter,
	getIfStakingHistoryFilter,
	getSettledBalancesHistoryFilter,
	getBalHistoryFilter,
	HistoryTableFilterContext,
} from 'src/utils/historyTables';
import { useLongTermDataApi } from './historyData/useLongTermDataApi';
import { ShortTermDataApiParams } from 'src/utils/DataApiClient';
import useDriftAccountStore from '../stores/useDriftAccountsStore';

const getSyncGenericHistoryTableState = (historyKey: HistoryKeyType) => {
	return (
		setAccountsStore: DriftAccountsStore['set'],
		params: SyncHistoryParams<HistoryKeyType>
	) => {
		const { userKey, history, totalCount, loading } = params;

		setAccountsStore((s) => {
			const currentState = s.accounts[userKey][historyKey];

			const baseState = {
				records: history,
				totalCount,
				loading,
				initialHistoryLoaded: currentState.initialHistoryLoaded || !loading,
			};

			if ('maxRecordLimit' in params) {
				(s.accounts[userKey][historyKey] as HistoryBaseStateWithMaxRecordLimit<
					HistoryRecordType[HistoryKeyType]
				>) = {
					...baseState,
					maxRecordLimit: params.maxRecordLimit,
				};
			} else {
				(s.accounts[userKey][historyKey] as HistoryBaseState<
					HistoryRecordType[HistoryKeyType]
				>) = baseState;
			}
		});
	};
};

const HISTORY_TYPE_CONFIG_LOOKUP: Partial<
	Record<
		HistoryType,
		{
			// syncs the fetched records to the accounts store
			syncHook: (
				setAccountsStore: DriftAccountsStore['set'],
				params: SyncHistoryParams<HistoryKeyType>
			) => void;
			// deserializes the records from the server
			deserializer: (
				r: Record<string, unknown>
			) => HistoryRecordType[HistoryKeyType];
			// filters the records based on the filter state
			filterFn?: (
				record: HistoryRecordType[HistoryKeyType],
				filterState: HistoryTableFilterStore[FilterableHistoryType]
			) => boolean;
		}
	>
> = {
	deposits: {
		syncHook: getSyncGenericHistoryTableState('depositWithdrawalHistory'),
		deserializer: Serializer.Deserialize.UIDeposit as (
			r: Record<string, unknown>
		) => HistoryRecordType['depositWithdrawalHistory'],
		filterFn: getDepositHistoryFilter as (
			record: HistoryRecordType[HistoryKeyType],
			filterState: HistoryTableFilterStore[FilterableHistoryType]
		) => boolean,
	},
	orders: {
		syncHook: getSyncGenericHistoryTableState('orderHistory'),
		deserializer: (r) =>
			convertV2OrderRecordToParentOrderInfo(
				// @ts-expect-error
				Serializer.Deserialize.UIOrderRecordV2(r)
			),
		// has its own custom filter function
	},
	trades: {
		syncHook: getSyncGenericHistoryTableState('tradeHistory'),
		deserializer: Serializer.Deserialize.DataApiUIOrderActionRecord as (
			r: Record<string, unknown>
		) => HistoryRecordType['tradeHistory'],
		filterFn: getTradesHistoryFilter as (
			record: HistoryRecordType[HistoryKeyType],
			filterState: HistoryTableFilterStore[FilterableHistoryType]
		) => boolean,
	},
	predictionTrades: {
		syncHook: getSyncGenericHistoryTableState('predictionsTradeHistory'),
		deserializer: Serializer.Deserialize.DataApiUIOrderActionRecord as (
			r: Record<string, unknown>
		) => HistoryRecordType['predictionsTradeHistory'],
		filterFn: getPredictionsHistoryFilter as (
			record: HistoryRecordType[HistoryKeyType],
			filterState: HistoryTableFilterStore[FilterableHistoryType]
		) => boolean,
	},
	['funding-payments']: {
		syncHook: getSyncGenericHistoryTableState('fundingHistory'),
		deserializer: Serializer.Deserialize.UIFundingPayment as (
			r: Record<string, unknown>
		) => HistoryRecordType['fundingHistory'],
		filterFn: getFundingHistoryFilter as (
			record: HistoryRecordType[HistoryKeyType],
			filterState: HistoryTableFilterStore[FilterableHistoryType]
		) => boolean,
	},
	['if-staking']: {
		syncHook: getSyncGenericHistoryTableState('ifStakeHistory'),
		deserializer: Serializer.Deserialize.UIInsuranceFundStakeRecord as (
			r: Record<string, unknown>
		) => HistoryRecordType['ifStakeHistory'],
		filterFn: getIfStakingHistoryFilter as (
			record: HistoryRecordType[HistoryKeyType],
			filterState: HistoryTableFilterStore[FilterableHistoryType]
		) => boolean,
	},
	['settled-balances']: {
		syncHook: getSyncGenericHistoryTableState('settlePnlHistory'),
		deserializer: Serializer.Deserialize.UISettlePnl as (
			r: Record<string, unknown>
		) => HistoryRecordType['settlePnlHistory'],
		filterFn: getSettledBalancesHistoryFilter as (
			record: HistoryRecordType[HistoryKeyType],
			filterState: HistoryTableFilterStore[FilterableHistoryType]
		) => boolean,
	},
	swaps: {
		syncHook: getSyncGenericHistoryTableState('swapHistory'),
		deserializer: Serializer.Deserialize.UISwapRecord as (
			r: Record<string, unknown>
		) => HistoryRecordType['swapHistory'],
	},
	bal: {
		syncHook: getSyncGenericHistoryTableState('lpHistory'),
		deserializer: Serializer.Deserialize.UILPRecord as (
			r: Record<string, unknown>
		) => HistoryRecordType['lpHistory'],
		filterFn: getBalHistoryFilter as (
			record: HistoryRecordType[HistoryKeyType],
			filterState: HistoryTableFilterStore[FilterableHistoryType]
		) => boolean,
	},
	liquidations: {
		syncHook: getLiquidationHistoryTableSync as (
			setAccountsStore: DriftAccountsStore['set'],
			params: SyncHistoryParams<HistoryKeyType>
		) => void,
		deserializer: Serializer.Deserialize.UILiquidationV2 as (
			r: Record<string, unknown>
		) => HistoryRecordType['liquidationHistory'],
	},
};

type HistoryTableStateProps = {
	executePageChange: (newPage: number, uiPageSize?: number) => unknown;
	hasNextPage: boolean;
};

export type HistoryTableUIProps = Pick<
	HistoryTableStateProps,
	'executePageChange'
> & {
	filterFn: (
		record: HistoryRecordType[HistoryKeyType],
		filterState: HistoryTableFilterStore[FilterableHistoryType],
		context?: HistoryTableFilterContext
	) => boolean;
};

const useShortTermGenericHistory = <T,>(
	historyType: HistoryType,
	enabled: boolean,
	shortTermApiParams?: ShortTermDataApiParams
): Omit<HistoryTableUIProps, 'filterFn'> => {
	const currentUserKey = useCurrentUserAccount()?.userKey;
	const setAccountsStore = useDriftAccountsStore((s) => s.set);
	const syncRecords = HISTORY_TYPE_CONFIG_LOOKUP[historyType].syncHook;

	const { allRecords, loading, executePageChange, knownMinRecords } =
		useShortTermDataApi<T>(
			historyType,
			HISTORY_TYPE_CONFIG_LOOKUP[historyType].deserializer as (
				r: Record<string, unknown>
			) => T,
			enabled,
			shortTermApiParams
		);

	useEffect(() => {
		if (!currentUserKey || !allRecords || !enabled) return;

		const totalCount = knownMinRecords;
		const maxRecordLimit = totalCount;

		syncRecords(setAccountsStore, {
			userKey: currentUserKey,
			history: allRecords as HistoryRecordType[HistoryKeyType][],
			totalCount,
			loading,
			maxRecordLimit,
		});
	}, [allRecords, loading, enabled, syncRecords]);

	return {
		executePageChange,
	};
};

const useLongTermGenericHistory = <T,>(
	historyType: FilterableHistoryType,
	enabled: boolean
): Omit<HistoryTableUIProps, 'filterFn'> => {
	const currentUserKey = useCurrentUserAccount()?.userKey;
	const setAccountsStore = useDriftAccountsStore((s) => s.set);
	const syncRecords = HISTORY_TYPE_CONFIG_LOOKUP[historyType].syncHook;

	const { allRecords, loading, executePageChange } = useLongTermDataApi<T>(
		historyType,
		HISTORY_TYPE_CONFIG_LOOKUP[historyType].deserializer as (
			r: Record<string, unknown>
		) => T,
		enabled
	);

	useEffect(() => {
		if (!currentUserKey || !allRecords || !enabled) return;

		const totalCount = allRecords.length;
		const maxRecordLimit = totalCount;

		syncRecords(setAccountsStore, {
			userKey: currentUserKey,
			history: allRecords as HistoryRecordType[HistoryKeyType][],
			totalCount,
			loading,
			maxRecordLimit,
		});
	}, [allRecords, loading, enabled, syncRecords]);

	return {
		executePageChange,
	};
};

export const useHistoryTableState = <T,>(
	historyType: FilterableHistoryType,
	shortTermApiParams?: ShortTermDataApiParams
): HistoryTableUIProps => {
	const mode = useHistoryTableFilterStore((s) => {
		return s.getFilterState(historyType).getFilterMode();
	});

	const isShortTerm = mode === 'SHORT_TERM';

	const { executePageChange: executeShortTermPageChange } =
		useShortTermGenericHistory<T>(historyType, isShortTerm, shortTermApiParams);
	const { executePageChange: executeLongTermPageChange } =
		useLongTermGenericHistory<T>(historyType, !isShortTerm);
	const setHistoryTableFilterStoreState = useHistoryTableFilterStore(
		(s) => s.set
	);
	const currentAccountPubKey = useDriftAccountStore((s) =>
		s.getCurrentUserAccount()?.pubKey?.toString()
	);

	// Reset the selected page any time the user account changes or this component is unmounted
	useEffect(() => {
		return () => {
			setHistoryTableFilterStoreState((s) => {
				if (!s[historyType]) return; // Not all history tabs have history table filter state

				s[historyType].currentUiPage = 0;
			});
		};
	}, [currentAccountPubKey]);

	return {
		executePageChange: isShortTerm
			? executeShortTermPageChange
			: executeLongTermPageChange,
		filterFn: HISTORY_TYPE_CONFIG_LOOKUP[historyType].filterFn,
	};
};
