'use client';

import { Info, Menu } from '@drift-labs/icons';
import { BigNum, MarketType } from '@drift-labs/sdk';
import { COMMON_UTILS } from '@drift/common';
import React from 'react';
import useDriftClient from 'src/hooks/useDriftClient';
import useShowAccountValues from '../../hooks/useShowAccountValues';
import Button from '../Button';
import TableActionButton from '../Buttons/TableActionButton';
import TableV2 from '../Tables/TableV2';
import Tooltip from '../Tooltip/Tooltip';
import BalanceActionsPopup from './BalanceActionsPopup';
import { UserBalanceInfo } from './UserBalancesPortfolioTable';
import useAprBreakdowns from 'src/hooks/useAprBreakdowns';
import AprBreakdownTooltip from '../Tooltip/AprBreakdownTooltip';
import CloseBorrowPopup, {
	CLOSE_BORROW_FORM_NESTED_POPUP_IDS,
} from '../Popups/CloseBorrowPopup';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import { twMerge } from 'tailwind-merge';
import { EXTRA_YIELD_SPOT_MARKETS } from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from '../../hooks/useDriftActions';
import NumberDisplayV2 from '../Utils/NumberDisplayV2';

const BalanceRow = (props: {
	highlighted?: boolean;
	className: string;
	tableGrid: string;
	expanded: boolean;
	spotMarket: UserBalanceInfo;
	index: number;
	isOverviewPage: boolean;
	handleSelection: (marketIndex: number) => void;
}) => {
	const driftClient = useDriftClient();
	const showAccountValues = useShowAccountValues();
	const aprBreakdowns = useAprBreakdowns();
	const showCollateralModal = useDriftActions().showCollateralModal;

	const {
		refs: closeBorrowPopupRefs,
		floatingStyles: closeBorrowPopupFloatingStyles,
		getReferenceProps: getCloseBorrowPopupReferenceProps,
		getFloatingProps: getCloseBorrowPopupFloatingProps,
		setIsPopoverOpen: setIsCloseBorrowPopupOpen,
		isPopoverOpen: isCloseBorrowPopupOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
		},
		{
			nestedPopoverIds: CLOSE_BORROW_FORM_NESTED_POPUP_IDS,
		}
	);

	const {
		refs: balanceActionsPopupRefs,
		floatingStyles: balanceActionsPopupFloatingStyles,
		getReferenceProps: getBalanceActionsPopupReferenceProps,
		getFloatingProps: getBalanceActionsPopupFloatingProps,
		setIsPopoverOpen: setIsBalanceActionsPopupOpen,
		isPopoverOpen: isBalanceActionsPopupOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: 'bottom-start',
		},
		{
			useFlip: true,
		}
	);

	const marketAprBreakdown =
		aprBreakdowns[props.spotMarket.spotMarketIndex] ?? [];
	const aprIcons = marketAprBreakdown
		.filter((aprDetails) => !!aprDetails.icon)
		.map((aprDetails) => aprDetails.icon);

	const showWeightsTooltip =
		props.spotMarket.assetWeight.toNum() !==
			props.spotMarket.defaultAssetWeight ||
		props.spotMarket.liabilityWeight.toNum() !==
			props.spotMarket.defaultLiabilityWeight;

	const lendingApr = COMMON_UTILS.getDepositAprForMarket(
		props.spotMarket.spotMarketIndex,
		MarketType.SPOT,
		driftClient
	);

	let displayApr = lendingApr;

	if (
		EXTRA_YIELD_SPOT_MARKETS.includes(props.spotMarket.spotMarketIndex) &&
		marketAprBreakdown?.[0]?.apr
	) {
		displayApr = lendingApr + marketAprBreakdown[0].apr;
	}

	return (
		<>
			<TableV2.BodyRow
				lastColumnJustify="start"
				key={`${props.index}_${props.spotMarket.symbol}`}
				grid={props.tableGrid}
				className={twMerge(['py-2', props.highlighted ? 'py-3' : undefined])}
				strongBottomBorder={props.highlighted}
			>
				<TableV2.MarketCell
					large={props.highlighted}
					key={`market_${props.index}`}
					marketSymbol={props.spotMarket.symbol}
					className="items-center"
					onClick={() =>
						props.handleSelection(props.spotMarket.spotMarketIndex)
					}
					highlight={props.highlighted}
				/>
				<TableV2.AssetCell
					key={`net_balance_${props.index}`}
					className="items-center"
					value={props.spotMarket.netBase}
					assetSymbol={props.spotMarket.symbol}
					isAccountValueToHide
					softenZeroValues
					notionalValue={props.spotMarket.netQuote}
					dataPuppetTag="balance_cell"
				/>

				<TableV2.BodyCell className="items-center">
					<AprBreakdownTooltip
						symbol={props.spotMarket.symbol}
						depositAprPercent={lendingApr}
						marketAprBreakdowns={marketAprBreakdown}
					>
						<div className="flex items-center divide-x divide-text-secondary">
							<div className="flex items-center gap-1">
								{aprIcons.map((icon, index) => (
									<img
										src={icon}
										key={`${icon}_${index}`}
										className="w-[14px] h-[14px] mr-1"
									/>
								))}
								<span className="pr-2 text-positive-green">
									{displayApr.toFixed(2)}%
								</span>
							</div>

							<span className="pl-2 text-warn-yellow">
								{COMMON_UTILS.getBorrowAprForMarket(
									props.spotMarket.spotMarketIndex,
									MarketType.SPOT,
									driftClient
								).toFixed(2)}
								%
							</span>
						</div>
					</AprBreakdownTooltip>
				</TableV2.BodyCell>

				<TableV2.NotionalCell
					key={`asset_liq_price_${props.index}`}
					className="items-center"
					value={props.spotMarket.liqPrice}
					textOverride={
						!showAccountValues
							? '*****'
							: props.spotMarket.liqPrice.gteZero()
							? null
							: 'None'
					}
					hoverTextOverride={
						'Liquidation Price is the Oracle Price of the asset where your account can be liquidated, assuming the prices of your other assets/positions are constant.'
					}
				/>

				{props.expanded && (
					<TableV2.BodyCell className="items-center">
						{showWeightsTooltip ? (
							<Tooltip
								allowHover
								content={
									<>
										{'IMF discount has been applied for this balance size.'}
										<a
											href="https://docs.drift.trade/getting-started/cross-collateral-deposits"
											target="_blank"
											rel="noreferrer"
											className="px-1"
										>
											Learn More
										</a>
										<br></br>
										<br></br>
										The asset and liability weights displayed are initial
										weights as opposed to maintenance weights. These are the
										weights applied towards collateral for risk-taking actions
										like opening new positions.
									</>
								}
							>
								<NumberDisplayV2
									value={props.spotMarket.assetWeight.div(BigNum.from(100))}
									displayType="percentage"
									toFixed={0}
								/>{' '}
								/{' '}
								<NumberDisplayV2
									value={props.spotMarket.liabilityWeight.div(BigNum.from(100))}
									displayType="percentage"
								/>
							</Tooltip>
						) : (
							`${props.spotMarket.assetWeight}% / ${props.spotMarket.liabilityWeight}%`
						)}
						{props.spotMarket.isLoweredAssetWeight && (
							<Tooltip
								allowHover
								content={
									<div className="flex flex-col">
										<span>
											The initial asset weight has been lowered to limit the
											margin extended by this token collateral to
											{` ${props.spotMarket.scaleInitialAssetWeightStart.toNotional()}`}
											. This does not impact existing positions or the
											maintenance asset weight.
										</span>

										<a
											className="underline text-text-interactive"
											href="https://docs.drift.trade/getting-started/cross-collateral-deposits"
											target="_blank"
											rel="noopener noreferrer"
										>
											Learn more
										</a>
									</div>
								}
							>
								<Info className="ml-1" color="var(--text-label)" />
							</Tooltip>
						)}
					</TableV2.BodyCell>
				)}

				<TableV2.BodyCell
					className="p-0 m-0 overflow-visible"
					key={`actions_${props.index}`}
				>
					<div className="flex items-center justify-start gap-2">
						{!props.isOverviewPage &&
							(props.spotMarket.borrowsBase.gtZero() ? (
								<span
									ref={closeBorrowPopupRefs.setReference}
									{...getCloseBorrowPopupReferenceProps()}
								>
									<TableActionButton
										label="Close Borrow"
										positive={false}
										handleClick={() => {
											setIsCloseBorrowPopupOpen(true);
										}}
										id={`closeBorrow_${props.spotMarket.spotMarketIndex.toString()}`}
										className="p-0 whitespace-nowrap"
									/>
								</span>
							) : (
								<Button.Secondary
									size="SMALL"
									onClick={() => {
										showCollateralModal('deposit', {
											bankIndex: props.spotMarket.spotMarketIndex,
										});
									}}
									highlight={props.highlighted}
								>
									Deposit
								</Button.Secondary>
							))}
						<Button.Secondary
							size="SMALL"
							onClick={() => {
								setIsBalanceActionsPopupOpen(true);
							}}
							ref={balanceActionsPopupRefs.setReference}
							{...getBalanceActionsPopupReferenceProps()}
						>
							<Menu size={16} />
						</Button.Secondary>
					</div>
				</TableV2.BodyCell>
			</TableV2.BodyRow>
			{isBalanceActionsPopupOpen && (
				<BalanceActionsPopup
					targetElementId={`balanceActions_${props.spotMarket.symbol}`}
					onClose={() => setIsBalanceActionsPopupOpen(false)}
					market={props.spotMarket}
					isOnBorrow={props.spotMarket.borrowsBase.gtZero()}
					setFloating={balanceActionsPopupRefs.setFloating}
					floatingStyles={balanceActionsPopupFloatingStyles}
					getFloatingProps={getBalanceActionsPopupFloatingProps}
				/>
			)}
			{isCloseBorrowPopupOpen && (
				<CloseBorrowPopup
					ref={closeBorrowPopupRefs.setFloating}
					style={closeBorrowPopupFloatingStyles}
					{...getCloseBorrowPopupFloatingProps()}
					spotMarketIndex={props.spotMarket.spotMarketIndex}
					onClose={() => setIsCloseBorrowPopupOpen(false)}
				/>
			)}
		</>
	);
};

export default BalanceRow;
